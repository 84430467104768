export function useVerificationCountryList() {
  const { countries } = useCountries()

  const allowedCountries = new Set([
    'ala',
    'dza',
    'and',
    'ago',
    'ata',
    'atg',
    'arg',
    'arm',
    'aze',
    'bhs',
    'bhr',
    'bgd',
    'bel',
    'blz',
    'ben',
    'btn',
    'bol',
    'bih',
    'bwa',
    'bvt',
    'bra',
    'brn',
    'bgr',
    'cmr',
    'can',
    'cpv',
    'tcd',
    'chl',
    'com',
    'cog',
    'cok',
    'hrv',
    'dji',
    'dma',
    'dom',
    'ecu',
    'egy',
    'slv',
    'gnq',
    'est',
    'eth',
    'fro',
    'fji',
    'fin',
    'gab',
    'gmb',
    'gha',
    'grc',
    'grl',
    'grd',
    'gtm',
    'ggy',
    'gin',
    'guy',
    'vat',
    'hnd',
    'hkg',
    'hun',
    'isl',
    'ind',
    'idn',
    'isr',
    'jpn',
    'jey',
    'kaz',
    'ken',
    'kir',
    'kor',
    'kwt',
    'kgz',
    'lao',
    'lva',
    'lso',
    'lie',
    'ltu',
    'lux',
    'mac',
    'mkd',
    'mdg',
    'mwi',
    'mys',
    'mdv',
    'mlt',
    'mrt',
    'mus',
    'mex',
    'mda',
    'mco',
    'mng',
    'mne',
    'nam',
    'nru',
    'npl',
    'nzl',
    'ner',
    'nga',
    'niu',
    'nor',
    'omn',
    'pak',
    'pse',
    'png',
    'pry',
    'per',
    'qat',
    'rwa',
    'kna',
    'lca',
    'vct',
    'wsm',
    'smr',
    'stp',
    'syc',
    'sle',
    'sgp',
    'svk',
    'svn',
    'slb',
    'zaf',
    'lka',
    'sur',
    'sjm',
    'swe',
    'che',
    'twn',
    'tjk',
    'tha',
    'tls',
    'tgo',
    'tkl',
    'ton',
    'tto',
    'tun',
    'tkm',
    'tuv',
    'ury',
    'uzb',
    'vut',
    'vnm',
    'esh',
    'zmb',
  ])

  return computed(() =>
    countries
      .filter((country) => allowedCountries.has(country.code))
      .map((country) => ({
        key: country.code,
        name: country.name,
        icon: country.icon,
      })),
  )
}
