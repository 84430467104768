<template>
  <section
    class="user-verification-form"
    :class="{ mobile: isMobile }"
    data-t="user-verification-form"
  >
    <h2 class="section-title">
      {{ t('userVerificationModal.personalInfo') }}
    </h2>

    <div class="fields">
      <div class="wrap">
        <StInput
          v-bind="firstName.componentBindings"
          :placeholder="t('userVerificationModal.firstNamePlaceholder')"
          data-t="first-name"
          autocomplete="given-name"
          :size="inputSize"
        />
        <StInput
          v-bind="lastName.componentBindings"
          :placeholder="t('userVerificationModal.lastNamePlaceholder')"
          data-t="last-name"
          autocomplete="family-name"
          :size="inputSize"
        />
      </div>
      <StInput
        v-bind="birthDate.componentBindings"
        :placeholder="
          t('userVerificationModal.birthDatePlaceholder', {
            format: dateFormat,
          })
        "
        data-t="birth-date"
        :mask="dateMask"
        autocomplete="bday"
        inputmode="numeric"
        :size="inputSize"
      />
    </div>

    <h2 class="section-title">{{ t('userVerificationModal.address') }}</h2>
    <div class="fields">
      <StSearch
        v-bind="country.componentBindings"
        :options="countrySelectOptions"
        :placeholder="t('userVerificationModal.countryPlaceholder')"
        :search-placeholder="t('userVerificationModal.inputSearch')"
        :drawer-title="t('userVerificationModal.countryPlaceholder')"
        data-t="country"
      />
      <div class="wrap">
        <StInput
          v-bind="town.componentBindings"
          :placeholder="t('userVerificationModal.cityPlaceholder')"
          data-t="town"
          autocomplete="address-level2"
          :size="inputSize"
        />
        <StInput
          v-bind="postCode.componentBindings"
          :placeholder="t('userVerificationModal.postalCodePlaceholder')"
          data-t="postal-code"
          autocomplete="postal-code"
          :size="inputSize"
        />
      </div>
      <StInput
        v-bind="address.componentBindings"
        :placeholder="t('userVerificationModal.addressPlaceholder')"
        data-t="address"
        autocomplete="street-address"
        :size="inputSize"
      />
    </div>

    <p class="security">
      <StIcon name="shield-check" :size="isMobile ? 12 : 16" />
      {{ t('userVerificationModal.confidentialInfo') }}
    </p>

    <StButton
      class="save-button"
      :size="isMobile ? 'l' : 'xl'"
      :loading="isLoading"
      @click="handleSubmit"
    >
      {{ t('userVerificationModal.saveButton') }}
    </StButton>

    <StButton
      v-if="withLogoutButton"
      class="logout-button"
      type="text-tertiary"
      size="m"
      @click="emit('logout')"
    >
      {{ t('userVerificationModal.logoutButton') }}
    </StButton>
  </section>
</template>

<script setup lang="ts">
import { useForm, required, lengthBetween } from '@st/validate'
import { useUserStore } from '@st/user/stores/useUserStore'
import { useVerificationCountryList } from './useVerificationCountryList'

const { isMobile } = usePlatform()
const { t, locale } = useI18n()
const rawStFetch = useRawStFetch()
const inputSize = computed(() => (isMobile.value ? 'm' : 'l'))
const userStore = useUserStore()

const { initialValues = {}, withLogoutButton = false } = defineProps<{
  withLogoutButton?: boolean
  initialValues?: {
    firstName?: string
    lastName?: string
    birthDate?: string
    country?: string
    town?: string
    address?: string
    postCode?: string
  }
}>()

const emit = defineEmits<{
  success: []
  logout: []
}>()

const countrySelectOptions = useVerificationCountryList()

/**
 * TODO спрятать этот кошмар куда-нибудь
 */
const { parseDate } = useDate()
const dateMask = computed(() => parseDate().format('L').replace(/[0-9]/g, '#'))
const dateFormat = computed(() =>
  parseDate('1111-01-02T00:00:00.000Z')
    .format('L')
    .replace('01', 'MM')
    .replace('02', 'DD')
    .replace('1111', 'YYYY'),
)

const formattedInitialValues = computed(() => ({
  firstName: initialValues.firstName ?? '',
  lastName: initialValues.lastName ?? '',
  birthDate: initialValues.birthDate
    ? parseDate(initialValues.birthDate, 'YYYY-MM-DD').format('L')
    : '',
  country: initialValues.country?.toLowerCase()?.slice(0, 3) ?? '',
  town: initialValues.town ?? '',
  address: initialValues.address ?? '',
  postCode: initialValues.postCode ?? '',
}))

const {
  fields: { firstName, lastName, birthDate, country, town, address, postCode },
  isValid,
  values,
  validate,
} = useForm({
  fieldsSchema: {
    firstName: {
      initialValue: formattedInitialValues.value.firstName,
      validators: [
        {
          rule: required,
          errorMessage: t('userVerificationModal.errors.required'),
        },
        {
          rule: lengthBetween(2, 50),
          errorMessage: t('userVerificationModal.errors.lengthBetween', {
            min: 2,
            max: 50,
          }),
        },
        {
          rule: (value) => /^[A-Za-z\s'-]{2,50}$/.test(value),
          errorMessage: t('userVerificationModal.errors.firstName'),
        },
      ],
    },
    lastName: {
      initialValue: formattedInitialValues.value.lastName,
      validators: [
        {
          rule: required,
          errorMessage: t('userVerificationModal.errors.required'),
        },
        {
          rule: lengthBetween(2, 50),
          errorMessage: t('userVerificationModal.errors.lengthBetween', {
            min: 2,
            max: 50,
          }),
        },
        {
          rule: (value) => /^[A-Za-z\s'-]{2,50}$/.test(value),
          errorMessage: t('userVerificationModal.errors.lastName'),
        },
      ],
    },
    birthDate: {
      initialValue: formattedInitialValues.value.birthDate,
      validators: [
        {
          rule: required,
          errorMessage: t('userVerificationModal.errors.required'),
        },
        {
          /**
           * последний параметр (true) - для строгого режима парсинга
           * иначе считаются валидными кривые даты типа 40/40/1999
           */
          rule: (value) =>
            parseDate(value.trim(), 'L', locale.value, true).isValid(),
          errorMessage: t('userVerificationModal.errors.birthDateInvalid'),
        },
        {
          rule: (value) => {
            const birthday = parseDate(value.trim(), 'L')
            const now = parseDate()

            return now.diff(birthday, 'years') >= 18
          },
          errorMessage: t('userVerificationModal.errors.ageRestriction'),
        },
      ],
    },
    country: {
      initialValue: formattedInitialValues.value.country,
      validators: [
        {
          rule: required,
          errorMessage: t('userVerificationModal.errors.required'),
        },
      ],
    },
    town: {
      initialValue: formattedInitialValues.value.town,
      validators: [
        {
          rule: required,
          errorMessage: t('userVerificationModal.errors.required'),
        },
        {
          rule: lengthBetween(2, 100),
          errorMessage: t('userVerificationModal.errors.lengthBetween', {
            min: 2,
            max: 100,
          }),
        },
        {
          rule: (value) => /^[A-Za-z\s-]{2,100}$/.test(value),
          errorMessage: t('userVerificationModal.errors.city'),
        },
      ],
    },
    address: {
      initialValue: formattedInitialValues.value.address,
      validators: [
        {
          rule: required,
          errorMessage: t('userVerificationModal.errors.required'),
        },
        {
          rule: lengthBetween(5, 200),
          errorMessage: t('userVerificationModal.errors.lengthBetween', {
            min: 5,
            max: 200,
          }),
        },
        {
          rule: (value) => /^[A-Za-z0-9\s,.-]{5,200}$/.test(value),
          errorMessage: t('userVerificationModal.errors.address'),
        },
      ],
    },
    postCode: {
      initialValue: formattedInitialValues.value.postCode,
      validators: [
        {
          rule: required,
          errorMessage: t('userVerificationModal.errors.required'),
        },
        {
          rule: lengthBetween(3, 12),
          errorMessage: t('userVerificationModal.errors.lengthBetween', {
            min: 3,
            max: 12,
          }),
        },
        {
          rule: (value) => /^[A-Za-z0-9\s-]{3,12}$/.test(value),
          errorMessage: t('userVerificationModal.errors.postalCode'),
        },
      ],
    },
  },
})

const toast = useToast()

const requestBody = computed(() => ({
  ...values.value,
  country: values.value.country.toUpperCase(),
  birthDate: parseDate(values.value.birthDate, 'L').format('YYYY-MM-DD'),
}))

const isLoading = ref(false)
async function handleSubmit() {
  validate()

  if (!isValid.value) {
    return
  }

  isLoading.value = true
  const { error } = await rawStFetch('/user-verification/unverified-data/set', {
    method: 'post',
    body: requestBody.value,
  })
  isLoading.value = false

  if (error) {
    toast.open({
      type: 'negative',
      label: t('userVerificationModal.errors.somethingWentWrong'),
    })
    return
  }

  if (userStore.user) userStore.user.kycLevel = 0
  emit('success')
}
</script>

<style scoped>
h2,
h3,
p {
  margin: 0;
}

.user-verification-form {
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.finish-registration {
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  padding-top: var(--spacing-300);
}

.wrap {
  display: flex;
  gap: var(--spacing-100);
}

.fields {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  margin-bottom: var(--spacing-200);
}

.section-title {
  margin-bottom: var(--spacing-125);
  font: var(--desktop-text-lg-semibold);
}

.security {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
  justify-content: center;

  margin-top: var(--spacing-100);

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}

.save-button {
  width: 100%;
  margin: var(--spacing-150) 0 0;
}

.logout-button {
  margin-top: var(--spacing-300);
}

.user-verification-form.mobile {
  width: auto;

  .section-title {
    font: var(--mobile-text-semibold);
  }

  .security {
    margin-top: 0;
  }
}
</style>
