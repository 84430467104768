<template>
  <StDrawer
    v-if="isMobile"
    v-model="modelValue"
    :is-embeded-drag-line="true"
    :with-overlay="true"
  >
    <StDrawerHeader
      :title="drawerTitle"
      with-drag-line-padding
      disable-router-back
      @close="modelValue = false"
    />
    <div class="drawer-content">
      <slot />
    </div>
  </StDrawer>
  <div v-else-if="modelValue" class="dropdown">
    <slot />
  </div>
</template>

<script setup lang="ts">
const { isMobile } = usePlatform()

const { drawerTitle = '' } = defineProps<{
  drawerTitle?: string
}>()

const modelValue = defineModel<boolean>({ default: false })
</script>

<style scoped>
.dropdown {
  all: unset;

  position: absolute;
  z-index: 2;
  top: calc(100% + 8px);

  overflow-y: auto;
  display: block;

  box-sizing: border-box;
  width: 100%;
  max-height: 248px;

  background: var(--background-primary);
  border: 1px solid var(--border-primary);
  border-radius: var(--border-radius-100);
  box-shadow:
    0 24px 48px -4px rgb(0 0 0 / 24%),
    0 8px 12px -4px rgb(0 0 0 / 24%);
}

.drawer-content {
  overflow: auto;
}
</style>
