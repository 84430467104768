<template>
  <div ref="search" class="st-search" :data-t="dataT">
    <SelectButton
      :first-value="selectedOption?.name"
      :icon-prefix="selectedOption?.icon ?? 'planet'"
      :icon-postfix="isShownDropdown ? 'chevron-top' : 'chevron-down'"
      :placeholder
      :label
      :error
      :error-message="errorMessage"
      :platform="platform"
      @click="isShownDropdown = !isShownDropdown"
    />
    <StSearchWrapper
      v-if="options.length"
      v-model="isShownDropdown"
      :drawer-title="drawerTitle"
    >
      <div class="search-input" :class="platform">
        <StInput
          v-model="searchTerm"
          icon-prefix="search"
          :placeholder="searchPlaceholder"
          :autofocus="platform === 'desktop'"
          :autocomplete="autocomplete"
          size="s"
        />
      </div>
      <ul>
        <li
          v-for="option in filteredOptions"
          :key="option.key"
          class="list-item"
          @click="selectOption(option)"
        >
          <StIcon :name="option.icon" class="result-icon" size="24" />
          <span class="item-name">{{ option.name }}</span>
          <StIcon
            v-if="selectedKey === option.key"
            name="check"
            class="check-icon"
          />
        </li>
      </ul>
      <div ref="list-end" />
    </StSearchWrapper>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '../StIcon/types'
import SelectButton from '../StSelect/parts/Select.vue'

interface SearchOption {
  name: string
  icon?: IconName
  key: string
}

interface Props {
  options: SearchOption[]
  label?: string
  placeholder?: string
  dataT?: string
  searchFunction?: (searchTerm: string, option: SearchOption) => boolean
  autocomplete?: string
  searchPlaceholder?: string
  drawerTitle?: string
  error?: boolean
  errorMessage?: string
}

const {
  options = [],
  label,
  placeholder,
  autocomplete,
  searchPlaceholder,
  dataT = 'st-search',
  drawerTitle,
  searchFunction = (searchTerm, option) =>
    option.name.toLowerCase().includes(searchTerm.trim().toLowerCase()),
} = defineProps<Props>()

const isShownDropdown = ref(false)
const { platform } = usePlatform()

const searchRef = useTemplateRef<HTMLDivElement>('search')
onClickOutside(searchRef, () => {
  if (platform.value === 'desktop') isShownDropdown.value = false
})

const selectedKey = defineModel<string>()
function selectOption(option: SearchOption) {
  selectedKey.value = option.key
  isShownDropdown.value = false
}

const selectedOption = computed(() =>
  options.find((option) => option.key === selectedKey.value),
)

const searchTerm = ref('')
const filteredOptions = computed(() =>
  searchTerm.value
    ? options.filter((option) => searchFunction(searchTerm.value, option))
    : options,
)
</script>

<style scoped>
ul,
li {
  all: unset;
  display: block;
  box-sizing: border-box;
}

.st-search {
  cursor: pointer;
  position: relative;
  width: 100%;
}

.list-item {
  display: flex;
  flex: 1 0 0;
  gap: var(--spacing-150);
  align-items: center;

  height: 48px;
  padding: var(--spacing-050) var(--spacing-150);
}

.list-item:hover {
  background: var(--background-secondary);
}

.check-icon {
  margin-left: auto;
  color: var(--icon-success);
}

.search-input {
  position: sticky;
  z-index: 10;
  top: 0;

  padding: var(--spacing-100) var(--spacing-150);

  background: var(--background-primary);

  &.mobile {
    padding: 0 var(--spacing-150) var(--spacing-100);
    background: var(--background-base);
  }
}
</style>
